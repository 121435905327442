import React, { useState, useEffect, ReactElement, useContext } from 'react';
import Link from 'next/link';

import { SnackbarKey, useSnackbar } from 'notistack';

import { Button } from '@material-ui/core';

import { Context } from '../pages/_app';
import { useQuery } from '@apollo/client';
import { findCurrentFormFromType, FormType } from 'lib/constants';
import { parseISO, isBefore } from 'date-fns';
import { OnboardingComponentViewDocument } from 'functions/types/graphql/firestore';

const advancedQuestionnaireFormId = findCurrentFormFromType(FormType.ADVANCED)?.slug;

type snackbarTypes = 'advQuestionnaire' | 'subscription' | 'initialPhotos' | 'otherTasks';

export default function OnboardingActions(): ReactElement {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [snackbarKeys, setSnackbarKeys] = useState({
    addKey: (type: snackbarTypes, new_key: SnackbarKey) => {
      setSnackbarKeys(s => ({
        ...s,
        [type]: [...(s[type] || []), new_key],
      }));
    },
    clearKeys: (type: snackbarTypes) => {
      setSnackbarKeys(s => {
        s[type].forEach((key: SnackbarKey) => closeSnackbar(key));
        return {
          ...s,
          [type]: [],
        };
      });
    },
    advQuestionnaire: [],
    subscription: [],
    initialPhotos: [],
    otherTasks: [],
  });

  const { isAuthenticated, uid } = useContext(Context);

  const { data } = useQuery(OnboardingComponentViewDocument, {
    variables: { id: uid },
    skip: !isAuthenticated,
    fetchPolicy: 'cache-and-network',
  });
  const user = data?.user;
  console.log('User', user);
  useEffect(() => {
    if (user) {
      const {
        classification,
        has_completed_adv_questionnaire,
        subscription_level,
        subscription_status,
        tasks,
      } = user;
      const { level } = classification || {};
      const needsInitialPhotosUpload = (tasks || []).some(task => {
        if (task?.done) return false;
        return task?.type === 'initial-photo-upload';
      });
      const hasOtherIncompleteTasks = (tasks || []).filter(({ type, done, due }) => {
        if (done) return false;
        if (type === 'initial-photo-upload') return false;
        if (!due || isBefore(new Date(), parseISO(due))) return false;
        if (
          type === 'advanced-questionnaire' &&
          !(user?.classification.level >= 3 && user?.subscription_level >= 3)
        )
          return false;
        return true;
      });

      if (
        snackbarKeys.advQuestionnaire.length +
          snackbarKeys.subscription.length +
          snackbarKeys.initialPhotos.length +
          snackbarKeys.otherTasks.length ===
        0
      ) {
        if (!has_completed_adv_questionnaire && level >= 3 && subscription_level >= 3) {
          if (snackbarKeys.advQuestionnaire.length === 0) {
            const newKey = enqueueSnackbar(
              'To allow us to provide you with advice, please complete our Advanced Questionnaire',
              {
                preventDuplicate: true,
                variant: 'error',
                persist: true,
                action: () => (
                  <Link href={`/form/${advancedQuestionnaireFormId}`} passHref>
                    <Button size="small" variant="contained" disableElevation>
                      start
                    </Button>
                  </Link>
                ),
              },
            );
            snackbarKeys.addKey('advQuestionnaire', newKey);
          }
        } else if (subscription_status !== 'active') {
          if (snackbarKeys.subscription.length === 0) {
            const newKey = enqueueSnackbar(
              'Please head to the Subscriptions section to choose a plan',
              {
                preventDuplicate: true,
                variant: 'error',
                persist: true,
                action: () => (
                  <Link href="/subscription" passHref>
                    <Button size="small" variant="contained" disableElevation>
                      subscribe
                    </Button>
                  </Link>
                ),
              },
            );
            snackbarKeys.addKey('subscription', newKey);
          }
        } else if (needsInitialPhotosUpload) {
          if (snackbarKeys.initialPhotos.length === 0) {
            const newKey = enqueueSnackbar(
              'Please upload your initial photos to help us assess you',
              {
                preventDuplicate: true,
                variant: 'error',
                persist: true,
                action: () => (
                  <Link href="/progress" passHref>
                    <Button size="small" variant="contained" disableElevation>
                      upload
                    </Button>
                  </Link>
                ),
              },
            );
            snackbarKeys.addKey('initialPhotos', newKey);
          }
        } else if (hasOtherIncompleteTasks.length > 0) {
          if (snackbarKeys.otherTasks.length === 0) {
            const newKey = enqueueSnackbar('You have outstanding tasks, please check them out!', {
              preventDuplicate: true,
              variant: 'warning',
              persist: true,
              action: key => (
                <Link href="/tasks" passHref>
                  <Button size="small" variant="contained" onClick={() => closeSnackbar(key)}>
                    tasks
                  </Button>
                </Link>
              ),
            });
            snackbarKeys.addKey('otherTasks', newKey);
          }
        }
      } else {
        if (has_completed_adv_questionnaire) {
          if (snackbarKeys.advQuestionnaire.length > 0) {
            snackbarKeys.clearKeys('advQuestionnaire');
          }
        }
        if (subscription_status === 'active') {
          if (snackbarKeys.subscription.length > 0) {
            snackbarKeys.clearKeys('subscription');
          }
        }
        if (!needsInitialPhotosUpload) {
          if (snackbarKeys.initialPhotos.length > 0) {
            snackbarKeys.clearKeys('initialPhotos');
          }
        }
        if (hasOtherIncompleteTasks.length === 0) {
          if (snackbarKeys.otherTasks.length > 0) {
            snackbarKeys.clearKeys('otherTasks');
          }
        }
      }
    }
  }, [user, snackbarKeys]);

  return <div></div>;
}
