import { ReactElement, useState, useEffect } from 'react';

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  NormalizedCacheObject,
  ApolloLink,
} from '@apollo/client';

import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { BatchHttpLink } from '@apollo/client/link/batch-http';

import { sha256 } from 'crypto-hash';

interface Props {
  children: ReactElement;
  token: string | undefined;
}

export default function GraphqlProvider({ children, token }: Props): ReactElement {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();
  const [cache] = useState(new InMemoryCache());

  useEffect(() => {
    if (!token && client) client.resetStore(); // reset the cache if there is no token and existing client, should only be hit on sign-out

    const link = ApolloLink.from([
      createPersistedQueryLink({ sha256 }),
      new BatchHttpLink({
        uri: '/api/graphql',
        headers: { authorization: token ? `Bearer ${token}` : '' },
      }),
    ]);

    setClient(new ApolloClient({ link, cache }));
  }, [token]);

  if (!client) return <div></div>;

  return <ApolloProvider client={client}>{children && children}</ApolloProvider>;
}
