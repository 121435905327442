import React, { useEffect, ReactElement, useContext, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Head from 'next/head';
import Image from 'next/image';
import ImageUploader from '../components/image-uploader';
import { reduce, startsWith } from 'lodash';
import { parseISO, isBefore } from 'date-fns';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import profileImg from './../public/assets/images/profile.png';
import {
  HomeOutlined,
  PersonOutlineOutlined,
  PeopleAltOutlined,
  MenuRounded,
  StarOutline,
  Kitchen,
  TrendingUp,
  NotificationsNoneOutlined,
  ShoppingCart,
  PersonOutline,
  ChatBubbleOutlineOutlined,
  Notes,
  BookOutlined,
  Payment,
  EventOutlined,
  SettingsOutlined,
  CreateOutlined,
} from '@material-ui/icons';
import { useMediaQuery } from '@material-ui/core';

import { Context, firebaseAuth } from '../pages/_app';
import { useQuery } from '@apollo/client';
import { signOut } from '@firebase/auth';
import {
  GetOwnNavDataDocument,
  GetTargetUserNavDataDocument,
} from 'functions/types/graphql/firestore';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: ({ sidebarWidth }: { sidebarWidth: string }) => sidebarWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: ({ sidebarWidth }: { sidebarWidth: string }) => sidebarWidth,
    background: theme.palette.primary.main,
    paddingLeft: '1rem',
    paddingTop: '2.5rem',
    borderRight: 'none',
  },
  logo: {
    marginLeft: '2rem',
    marginBottom: '2rem',
    cursor: 'pointer',
  },
  toolbarLogo: {
    cursor: 'pointer',
  },
  divider: {
    margin: '1.5rem 0',
  },
  header: {
    color: theme.palette.common.white,
    padding: '0.25rem 0 0.25rem 2rem',
    margin: '0.25rem 0',
    borderTopLeftRadius: '15px',
    borderBottomLeftRadius: '15px',
    '& span': {
      fontWeight: 500,
    },
  },
  link: {
    color: theme.palette.common.white,
    cursor: 'pointer',
    padding: '0.25rem 0 0.25rem 2rem',
    margin: '0.25rem 0',
    borderTopLeftRadius: '15px',
    borderBottomLeftRadius: '15px',
    transition: theme.transitions.create(['background', 'color'], {
      duration: 350,
    }),
    '&:hover': {
      background: theme.palette.background.default,
      color: theme.palette.primary.main,
      '& svg': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiListItemIcon-root': {
      minWidth: '0',
      paddingRight: '1rem',
    },
    '& svg': {
      color: theme.palette.common.white,
      transition: theme.transitions.create('color', {
        duration: 350,
      }),
    },
  },
  active: {
    background: theme.palette.background.default,
    color: theme.palette.primary.main,
    '& svg': {
      color: theme.palette.primary.main,
    },
  },
  signInOut: {
    marginTop: '1rem',
    marginBottom: 0,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.5rem 2rem',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      color: theme.palette.common.white,
    },
  },
  floatingActions: {
    position: 'fixed',
    top: '1.5rem',
    right: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    padding: '0rem 0.1rem',
    borderRadius: '8px',
    background: theme.palette.primary.main,
    '& > *': {
      color: theme.palette.common.white,
    },
    zIndex: 1000,
  },
  profile: {
    textAlign: 'center',
    color: 'white',
    position: 'relative',
  },
  addProfile: {
    position: 'absolute',
    top: '82%',
    left: '54%',
    cursor: 'pointer',
    background: '#fff',
    borderRadius: '100%',
    padding: '5px',
    '& svg': {
      fill: '#8a8cbd',
    },
  },
  profileImage: {
    margin: '0 auto 1rem auto',
    position: 'relative',
    border: '3px solid #fff !important',
    borderRadius: '100% !important',
  },
  sublist: {
    paddingLeft: '1rem',
  },
}));

interface NavProps {
  sidebarWidth: string;
  isSidebarOpen: boolean;
  setIsSidebarRequestedOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Nav({
  sidebarWidth,
  isSidebarOpen,
  setIsSidebarRequestedOpen,
}: NavProps): ReactElement {
  const classes = useStyles({ sidebarWidth });
  const router = useRouter();
  const { pathname, query } = router;
  const [dialogType, setDialogType] = useState<string>();
  const _closeDialog = () => setDialogType(undefined);
  const { cartBag, is_admin, isAuthenticated, uid, ownMessages: messages } = useContext(Context);
  const unreadMessagesCount = messages.reduce((acc: number, message) => {
    if (message.sender_type === 'admin' && !message.read_at) return acc + 1;
    return acc;
  }, 0);

  const { data: selfData } = useQuery(GetOwnNavDataDocument, {
    variables: { id: uid },
    skip: !isAuthenticated,
    fetchPolicy: 'cache-and-network',
  });
  const user = selfData?.user;
  const subscriptionLevel = user?.subscription_level;
  const hasActiveSubscription = user?.subscription_status === 'active';
  const target_id =
    is_admin && startsWith(pathname, '/admin') && query.user
      ? Array.isArray(query.user)
        ? query.user[0]
        : query.user
      : false;
  const { data: targetResData } = useQuery(GetTargetUserNavDataDocument, {
    variables: {
      id: target_id || '',
    },
    skip: !isAuthenticated || !target_id,
    fetchPolicy: 'cache-and-network',
  });
  const targetUser = targetResData?.user;

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const countOutstandingTasks = reduce(
    user?.tasks || [],
    (acc, task) => {
      if (!task || task.done) return acc;
      if (
        task.type === 'advanced-questionnaire' &&
        !(
          (user?.classification?.level && user.classification.level >= 3) ||
          (user?.subscription_level && user?.subscription_level >= 3)
        )
      )
        return acc;
      if (isBefore(new Date(), parseISO(task.due))) return acc;

      return acc + 1;
    },
    0,
  );

  useEffect(() => setIsSidebarRequestedOpen(false), [pathname, query]);
  const { cart } = cartBag;
  const cartSize = cart.length;

  const profileImageUrl = user?.profile_image?.url;
  const name = `${user?.first_name} ${user?.last_name}`;

  const targetUserName =
    typeof targetUser === 'object'
      ? `${targetUser?.first_name}${targetUser?.last_name ? ` ${targetUser?.last_name}` : ''}`
      : false;

  const title =
    is_admin && startsWith(pathname, '/admin')
      ? targetUserName
        ? `SkinKitz Admin: ${targetUserName}`
        : 'SkinKitz Admin'
      : 'SkinKitz Dashboard';

  const fullTitle = `${process.env.NEXT_PUBLIC_ENV === 'staging' ? 'Staging ' : ''}${title}`;

  return (
    <>
      <Head>
        <title>{fullTitle}</title>
      </Head>
      <Drawer
        className={classes.drawer}
        variant={isSmall || pathname === '/admin' ? 'temporary' : 'persistent'}
        anchor="left"
        open={isSidebarOpen}
        onClose={
          isSmall || pathname === '/admin'
            ? () => setIsSidebarRequestedOpen(false)
            : () => {
                /* empty */
              }
        }
        classes={{ paper: classes.drawerPaper }}
      >
        <div className={classes.logo}>
          <Image
            width={64}
            height={64}
            src="/assets/logos/white.png"
            onClick={() =>
              startsWith(pathname, '/admin') ? router.push('/admin') : router.push('/')
            }
            className={classes.logo}
            layout="fixed"
            alt="logo"
          />
        </div>
        <div className={classes.profile}>
          {user?.first_name && (
            <Typography variant="h5" paragraph>
              <span className="uppercase">{name}</span>
            </Typography>
          )}
          <div>
            <Image
              layout="fixed"
              objectFit={profileImageUrl ? 'cover' : 'contain'}
              alt="users profile photo"
              className={classes.profileImage}
              src={profileImageUrl || profileImg}
              width={140}
              height={140}
            />
            {is_admin && (
              <span className={classes.addProfile}>
                <CreateOutlined onClick={() => setDialogType('profile-upload')}></CreateOutlined>
              </span>
            )}
          </div>
        </div>
        <List>
          {isAuthenticated ? (
            <>
              {is_admin && (
                <>
                  <ListItem className={classes.header}>
                    <ListItemText primary="Admin Mode" />
                  </ListItem>
                  <Link href="/admin" passHref>
                    <ListItem
                      button
                      component="a"
                      className={`${classes.link} ${pathname === '/admin' ? classes.active : ''}`}
                    >
                      <ListItemIcon>
                        <PeopleAltOutlined fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="All Users" />
                    </ListItem>
                  </Link>
                  <Link href="/admin/schedule" passHref>
                    <ListItem
                      button
                      component="a"
                      className={`${classes.link} ${
                        pathname === '/admin/schedule' ? classes.active : ''
                      }`}
                    >
                      <ListItemIcon>
                        <EventOutlined fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="My Schedule" />
                    </ListItem>
                  </Link>
                  <Link href="/admin/settings" passHref>
                    <ListItem
                      button
                      component="a"
                      className={`${classes.link} ${
                        pathname === '/admin/settings' ? classes.active : ''
                      }`}
                    >
                      <ListItemIcon>
                        <SettingsOutlined fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="My Settings" />
                    </ListItem>
                  </Link>
                  {target_id && targetUserName && (
                    <List className={classes.sublist}>
                      <Link href={`/admin/${target_id}`} passHref>
                        <ListItem
                          button
                          component="a"
                          className={`${classes.link} ${
                            pathname === '/admin/[user]' ? classes.active : ''
                          }`}
                        >
                          <ListItemIcon>
                            <PersonOutline fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary={targetUserName} />
                        </ListItem>
                      </Link>
                      <Link href={`/admin/${target_id}/chat`} passHref>
                        <ListItem
                          button
                          component="a"
                          className={`${classes.link} ${
                            pathname === '/admin/[user]/chat' ? classes.active : ''
                          }`}
                        >
                          <ListItemIcon>
                            <ChatBubbleOutlineOutlined fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary="Chat" />
                        </ListItem>
                      </Link>
                      <Link href={`/admin/${target_id}/notes`} passHref>
                        <ListItem
                          button
                          component="a"
                          className={`${classes.link} ${
                            pathname === '/admin/[user]/notes' ? classes.active : ''
                          }`}
                        >
                          <ListItemIcon>
                            <Notes fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary="Notes" />
                        </ListItem>
                      </Link>
                      <Link href={`/admin/${target_id}/assign-products`} passHref>
                        <ListItem
                          button
                          component="a"
                          className={`${classes.link} ${
                            pathname === '/admin/[user]/assign-products' ? classes.active : ''
                          }`}
                        >
                          <ListItemIcon>
                            <BookOutlined fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary="Assign Products" />
                        </ListItem>
                      </Link>
                    </List>
                  )}
                </>
              )}
              {!is_admin && (
                <>
                  <Link href="/" passHref>
                    <ListItem
                      button
                      component="a"
                      className={`${classes.link} ${pathname === '/' ? classes.active : ''}`}
                    >
                      <ListItemIcon>
                        <HomeOutlined fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Overview" />
                    </ListItem>
                  </Link>
                  <Link href="/chat" passHref>
                    <ListItem
                      button
                      component="a"
                      className={`${classes.link} ${
                        startsWith(pathname, '/chat') ? classes.active : ''
                      }`}
                    >
                      <ListItemIcon>
                        <ChatBubbleOutlineOutlined fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={`Chat: ${unreadMessagesCount || 0}`} />
                    </ListItem>
                  </Link>
                  <Link href="/tasks" passHref>
                    <ListItem
                      button
                      component="a"
                      className={`${classes.link} ${
                        startsWith(pathname, '/tasks') ? classes.active : ''
                      }`}
                    >
                      <ListItemIcon>
                        <NotificationsNoneOutlined fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={`Your Tasks: ${countOutstandingTasks}`} />
                    </ListItem>
                  </Link>
                  <Link href="/recommended-products" passHref>
                    <ListItem
                      button
                      component="a"
                      className={`${classes.link} ${
                        startsWith(pathname, '/recommended-products') ? classes.active : ''
                      }`}
                    >
                      <ListItemIcon>
                        <Kitchen fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Recommended Products" />
                    </ListItem>
                  </Link>
                  <Link href="/tips" passHref>
                    <ListItem
                      button
                      component="a"
                      className={`${classes.link} ${
                        startsWith(pathname, '/tips') ? classes.active : ''
                      }`}
                    >
                      <ListItemIcon>
                        <StarOutline fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Lifestyle Tips" />
                    </ListItem>
                  </Link>
                  <Link href="/progress" passHref>
                    <ListItem
                      button
                      component="a"
                      className={`${classes.link} ${
                        startsWith(pathname, '/progress') ? classes.active : ''
                      }`}
                    >
                      <ListItemIcon>
                        <TrendingUp fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Progress Photos" />
                    </ListItem>
                  </Link>
                  {subscriptionLevel === 4 && hasActiveSubscription && (
                    <Link href="/consultations" passHref>
                      <ListItem
                        button
                        component="a"
                        className={`${classes.link} ${
                          startsWith(pathname, '/consultations') ? classes.active : ''
                        }`}
                      >
                        <ListItemIcon>
                          <EventOutlined fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Video Consultations" />
                      </ListItem>
                    </Link>
                  )}
                  <Link href="/account" passHref prefetch={false}>
                    <ListItem
                      button
                      component="a"
                      className={`${classes.link} ${
                        startsWith(pathname, '/account') ? classes.active : ''
                      }`}
                    >
                      <ListItemIcon>
                        <PersonOutlineOutlined fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Account Management" />
                    </ListItem>
                  </Link>
                  <Link href="/subscription" passHref>
                    <ListItem
                      button
                      component="a"
                      className={`${classes.link} ${
                        startsWith(pathname, '/subscription') ? classes.active : ''
                      }`}
                    >
                      <ListItemIcon>
                        <Payment fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Subscription" />
                    </ListItem>
                  </Link>
                </>
              )}
              <ListItem
                button
                className={`${classes.link} ${classes.signInOut}`}
                onClick={async () => {
                  await signOut(firebaseAuth);
                  window.location.reload();
                }}
              >
                <ListItemText primary="Sign Out" />
              </ListItem>
            </>
          ) : (
            <Link href="/login" passHref>
              <ListItem
                button
                component="a"
                className={`${classes.link} ${classes.signInOut} ${
                  pathname === '/login' ? classes.active : ''
                }`}
              >
                <ListItemText primary="Sign In To Dashboard" />
              </ListItem>
            </Link>
          )}
        </List>
        <Dialog open={Boolean(dialogType)} onClose={_closeDialog}>
          {dialogType === 'profile-upload' && (
            <>
              <DialogTitle>Upload Profile Photo</DialogTitle>
              <DialogContent>
                <ImageUploader handleClose={_closeDialog} type="profile" />
              </DialogContent>
            </>
          )}
        </Dialog>
      </Drawer>
      {!isSmall && isAuthenticated && cartSize > 0 && !is_admin && (
        <div className={classes.floatingActions}>
          <Link href="/cart" passHref>
            <IconButton>
              <ShoppingCart color="inherit" />
              <Typography variant="body1">{cartSize}</Typography>
            </IconButton>
          </Link>
        </div>
      )}
      {(isSmall || pathname === '/admin') && isAuthenticated && (
        <AppBar position="sticky">
          <Toolbar className={classes.toolbar}>
            <Image
              width={48}
              height={48}
              src="/assets/logos/white.png"
              onClick={() =>
                startsWith(pathname, '/admin') ? router.push('/admin') : router.push('/')
              }
              className={classes.toolbarLogo}
              layout="fixed"
              alt="logo"
            />
            <div className={classes.actions}>
              {!is_admin && (
                <Link href="/cart" passHref>
                  <IconButton>
                    <ShoppingCart color="inherit" />
                    <Typography variant="body1">{cartSize}</Typography>
                  </IconButton>
                </Link>
              )}
              <IconButton onClick={() => setIsSidebarRequestedOpen(e => !e)}>
                <MenuRounded color="inherit" />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
}
