import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import type { AppProps } from 'next/app';
import type { ReactElement } from 'react';

import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collection, where, doc, onSnapshot, getFirestore, query } from 'firebase/firestore';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';

import startsWith from 'lodash/startsWith';
import Router, { useRouter } from 'next/router';
import NProgress from 'nprogress';
import { differenceInSeconds, parseISO } from 'date-fns';

import Theme from 'components/theme';
import Splash from 'components/splash';
import Nav from 'components/nav';
import GraphqlProvider from 'components/graphql-provider';
import OnboardingActions from 'components/onboarding';

import { CartItem, Firestore, CartBag, FirestoreMessage } from 'types';

import './styles.css';

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());
NProgress.configure({ showSpinner: false, easing: 'ease', speed: 1500 });

const firebaseConfig =
  process.env.NEXT_PUBLIC_ENV === 'production'
    ? {
        apiKey: 'AIzaSyAg4Y461ma7Qso5sjHaz94rZbt69RdE1rE',
        authDomain: 'skitkitz-dash-production.firebaseapp.com',
        projectId: 'skitkitz-dash-production',
        storageBucket: 'skitkitz-dash-production.appspot.com',
        messagingSenderId: '634746072930',
        appId: '1:634746072930:web:0dffd9dc99af598500063b',
      }
    : {
        apiKey: 'AIzaSyD9XIBblwEqdSzAVnD7OBpzqRJE03R07ms',
        authDomain: 'skinkitz-staging.firebaseapp.com',
        projectId: 'skinkitz-staging',
        storageBucket: 'skinkitz-staging.appspot.com',
        messagingSenderId: '572084883362',
        appId: '1:572084883362:web:47cb5858ccec1206cb2cfb',
      };

const firebaseApp = initializeApp(firebaseConfig);
const firebaseAuth = getAuth(firebaseApp);
const firestore = getFirestore();

export { firebaseAuth, firestore };

interface StyleProps {
  isSplashing: boolean;
  innerHeight: string | number;
  sidebarWidth: string | number;
  isSidebarOpen: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '100vw',
    height: ({ isSplashing, innerHeight }: StyleProps) => (isSplashing ? innerHeight : 'auto'),
    minHeight: ({ isSplashing, innerHeight }: StyleProps) => (isSplashing ? innerHeight : '100vh'),
    overflow: ({ isSplashing }: StyleProps) => (isSplashing ? 'hidden' : 'unset'),
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
  },
  drawerShift: {
    transition: theme.transitions.create('margin-left'),
    marginLeft: 0,
    [theme.breakpoints.up('md')]: {
      marginLeft: ({ sidebarWidth, isSidebarOpen }: StyleProps) =>
        isSidebarOpen ? sidebarWidth : 0,
    },
  },
  successSnackbar: {
    backgroundColor: '#8A8CBD',
  },
}));

const sidebarWidth = 'clamp(18rem, 25vw, 24rem)';

export const Context = React.createContext<ContextData>({
  is_admin: false,
  isAuthenticated: false,
  authLoading: true,
  token: '',
  uid: '',
  cartBag: {
    cart: [],
    add: () => {
      /* */
    },
    remove: () => {
      /* */
    },
    clear: () => {
      /* */
    },
  },
  updateContext: () => undefined,
  ownMessages: [],
  targetUserMessages: [],
});

export default function App({ Component, pageProps }: AppProps): ReactElement {
  const router = useRouter();
  const { pathname } = router;

  const queryUid: string = Array.isArray(router.query.user)
    ? router.query.user[0]
    : router.query.user;

  const [contextState, setContextState] = useState<ContextData>({
    is_admin: false,
    isAuthenticated: false,
    token: '',
    uid: '',
    authLoading: true,
    ownMessages: [],
    targetUserMessages: [],
    cartBag: {
      cart: [],
      add: (product: Firestore.AssignedProduct): void =>
        setContextState((c: ContextData): ContextData => {
          return c.cartBag.cart.some(item => item.product.id === product.id)
            ? c
            : { ...c, cartBag: { ...c.cartBag, cart: [...(c.cartBag.cart || []), { product }] } };
        }),
      remove: (item: CartItem): void =>
        setContextState(
          (c: ContextData): ContextData => ({
            ...c,
            cartBag: {
              ...c.cartBag,
              cart: c.cartBag.cart.filter(curr => curr.product.id !== item.product.id),
            },
          }),
        ),
      clear: () =>
        setContextState(
          (c: ContextData): ContextData => ({ ...c, cartBag: { ...c.cartBag, cart: [] } }),
        ),
    },
    updateContext: (update: { [key: string]: unknown }) =>
      setContextState((c: ContextData): ContextData => ({ ...c, ...update })),
  });
  const { updateContext, is_admin, token, authLoading, isAuthenticated } = contextState;

  useEffect(() => {
    onAuthStateChanged(
      firebaseAuth,
      async user => {
        try {
          const token = user ? await user.getIdToken(true) : false;

          if (!token || !user) {
            return updateContext({
              token: '',
              is_admin: false,
              uid: '',
              authLoading: false,
              isAuthenticated: false,
            });
          }

          const tokenResults = await user.getIdTokenResult(true);

          const dashboardClaims = tokenResults.claims['https://dashboard.skinkitz.com.au/'] as {
            is_admin: boolean;
          };
          const is_admin =
            dashboardClaims && typeof dashboardClaims === 'object'
              ? dashboardClaims.is_admin
              : false;

          updateContext({
            token,
            uid: user.uid,
            authLoading: false,
            isAuthenticated: true,
            is_admin,
          });

          if (contextState.ownMessagesUnsub) contextState.ownMessagesUnsub();

          const ownMessagesUnsub = onSnapshot(
            query(
              collection(doc(firestore, 'users', user.uid), 'messages'),
              where('type', '==', 'chat'),
            ),
            docs => {
              const ownMessages = docs.empty
                ? []
                : docs.docs
                    .map(
                      doc =>
                        ({
                          id: doc.id,
                          ...doc.data(),
                        } as FirestoreMessage),
                    )
                    .sort((a: FirestoreMessage, b: FirestoreMessage) =>
                      differenceInSeconds(parseISO(a.sent_at), parseISO(b.sent_at)),
                    );
              updateContext({ ownMessages });
            },
          );

          updateContext({ ownMessagesUnsub });
        } catch (e) {
          console.error(e);
        }
      },
      error => {
        console.error('authError', error);
      },
    );
  }, []);

  useEffect(() => {
    if (contextState.targetUserMessagesUnsub) contextState.targetUserMessagesUnsub();

    if (queryUid && contextState.is_admin) {
      const targetUserMessagesUnsub = onSnapshot(
        query(
          collection(doc(firestore, 'users', queryUid), 'messages'),
          where('type', '==', 'chat'),
        ),
        docs => {
          const targetUserMessages = docs.empty
            ? []
            : docs.docs
                .map(
                  doc =>
                    ({
                      id: doc.id,
                      ...doc.data(),
                    } as FirestoreMessage),
                )
                .sort((a: FirestoreMessage, b: FirestoreMessage) =>
                  differenceInSeconds(parseISO(a.sent_at), parseISO(b.sent_at)),
                );
          updateContext({ targetUserMessages });
        },
      );

      updateContext({ targetUserMessagesUnsub });
    }
  }, [queryUid, is_admin]);

  const [isSidebarRequestedOpen, setIsSidebarRequestedOpen] = useState(false);
  const theme = useTheme();
  const breakpointMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const isSidebarOpen =
    (isAuthenticated &&
      breakpointMdUp &&
      pathname !== '/admin' &&
      !startsWith(pathname, '/welcome') &&
      !startsWith(pathname, '/reset-password') &&
      !startsWith(pathname, '/form')) ||
    isSidebarRequestedOpen;

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && typeof jssStyles === 'object' && jssStyles.parentElement)
      jssStyles.parentElement.removeChild(jssStyles);
  }, []);

  const isOnPublicRoute =
    pathname.startsWith('/forgotten-password') ||
    pathname.startsWith('/login') ||
    pathname.startsWith('/reset-password') ||
    pathname.startsWith('/welcome');

  const isSplashing = authLoading || (!isAuthenticated && !isOnPublicRoute);

  const classes = useStyles({
    isSidebarOpen,
    sidebarWidth,
    isSplashing,
    innerHeight: typeof window !== 'undefined' ? window.innerHeight : '100vh',
  });

  if (!authLoading) {
    if (!isAuthenticated) {
      if (!isOnPublicRoute) {
        router.push('/login');
        return <Splash />;
      }
    } else {
      if (pathname.startsWith('/login')) {
        router.push('/');
        return <Splash />;
      } else if (is_admin && !pathname.startsWith('/admin')) {
        router.push('/admin');
        return <Splash />;
      }
    }
  }

  const fullTitle = `${
    process.env.NEXT_PUBLIC_ENV === 'staging' ? 'Staging ' : ''
  }SkinKitz Dashboard`;

  return (
    <>
      <Head>
        <title>{fullTitle}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <div id="app" className={classes.root} onDragOver={e => e.preventDefault()}>
        <Theme>
          <GraphqlProvider token={token}>
            <Context.Provider value={contextState}>
              <SnackbarProvider
                maxSnack={1}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                preventDuplicate
                autoHideDuration={2000}
                classes={{
                  variantSuccess: classes.successSnackbar,
                }}
                hideIconVariant
              >
                {isAuthenticated && (
                  <>
                    {!pathname.startsWith('/form') && !isOnPublicRoute && !is_admin && (
                      <OnboardingActions />
                    )}
                    <Nav
                      sidebarWidth={sidebarWidth}
                      isSidebarOpen={isSidebarOpen}
                      setIsSidebarRequestedOpen={setIsSidebarRequestedOpen}
                    />
                  </>
                )}
                <div className={classes.drawerShift}>
                  {isSplashing ? <Splash /> : <Component {...pageProps} />}
                </div>
              </SnackbarProvider>
            </Context.Provider>
          </GraphqlProvider>
        </Theme>
      </div>
    </>
  );
}

export type ContextUpdate = {
  cartBag?: CartBag;
  isAuthenticated?: boolean;
  is_admin?: boolean;
  token?: string;
  uid?: string;
  authLoading?: boolean;
  ownMessagesUnsub?: () => void;
  ownMessages?: FirestoreMessage[];
  targetUserMessagesUnsub?: () => void;
  targetUserMessages?: FirestoreMessage[];
};

interface ContextData extends ContextUpdate {
  cartBag: CartBag;
  isAuthenticated: boolean;
  is_admin: boolean;
  token: string;
  uid: string;
  authLoading: boolean;
  updateContext: (update: ContextUpdate) => void;
  ownMessagesUnsub?: () => void;
  ownMessages: FirestoreMessage[];
  targetUserMessagesUnsub?: () => void;
  targetUserMessages: FirestoreMessage[];
}
