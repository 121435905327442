import React from 'react';

import { CssBaseline } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const headingFontFamily =
  '"Heebo", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';
const bodyFontFamily =
  '"Open Sans", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';

const theme = createTheme({
  palette: {
    primary: {
      light: '#F2F2F7',
      main: '#8A8CBD',
      dark: '#6D57C2',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#59D0C7',
      contrastText: '#000',
    },
    background: {
      default: '#F5F5FE',
    },
  },
  typography: {
    fontFamily: bodyFontFamily,

    h1: {
      fontFamily: headingFontFamily,
      fontWeight: 700,
    },
    h2: {
      fontFamily: headingFontFamily,
      fontWeight: 700,
    },
    h3: {
      fontFamily: headingFontFamily,
      fontWeight: 700,
    },
    h4: {
      fontFamily: headingFontFamily,
      fontWeight: 700,
    },
    h5: {
      fontFamily: headingFontFamily,
      fontWeight: 700,
    },
    h6: {
      fontFamily: headingFontFamily,
      fontWeight: 700,
      textTransform: 'uppercase',
      fontSize: '1rem',
    },
  },
});

export default function Theme({ children }: { children: React.ReactNode }): React.ReactElement {
  return (
    <ThemeProvider theme={theme}>
      <Baseline>{children && children}</Baseline>
    </ThemeProvider>
  );
}

function Baseline({ children }) {
  return (
    <>
      <CssBaseline />
      {children && children}
    </>
  );
}
