import React, { useState, ReactElement } from 'react';

import { CircularProgress, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ProgressPhotoSides } from 'types';
import { useMutation } from '@apollo/client';
import {
  FinaliseImageUploadDocument,
  GetImageUploadUrlDocument,
} from 'functions/types/graphql/firestore';

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
    marginBottom: '1rem',
  },
});

interface ImageUploadProps {
  handleClose: () => void;
  type: 'profile' | 'progress';
  period?: string;
  side?: ProgressPhotoSides;
}
interface ProfileImageUploadProps {
  handleClose: () => void;
  type: 'profile';
  period?: undefined;
  side?: undefined;
}
interface ProgressImageUploadProps {
  handleClose: () => void;
  type: 'progress';
  period: string;
  side: ProgressPhotoSides;
}

function Uploader({ handleClose, period, type, side }: ProfileImageUploadProps): ReactElement;
function Uploader({ handleClose, period, type, side }: ProgressImageUploadProps): ReactElement;
function Uploader({ handleClose, period, type, side }: ImageUploadProps): ReactElement {
  const classes = useStyles();

  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(false);

  const [getImageUploadUrl] = useMutation(GetImageUploadUrlDocument);
  const [doFinialiseUplaod] = useMutation(FinaliseImageUploadDocument);

  const logProgress = (input: number) => {
    setProgress(Math.round(input * 100));
  };

  const _handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    logProgress(0.01);
    setError(false);

    const file = e.target.files && e.target.files[0];
    if (!file) return;

    try {
      const reader = new FileReader();

      reader.onloadend = async data => {
        try {
          const dataUrl = data.target?.result;
          if (!dataUrl) return;

          let resolve: (value: boolean) => void;
          const promise = new Promise(res => (resolve = res));

          const imgEl = document.createElement('img');
          imgEl.onload = async data => {
            logProgress(0.05);

            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            const target = data.target as OffscreenCanvas;

            canvas.width = target.width;
            canvas.height = target.height;

            ctx?.drawImage(target, 0, 0, target.width, target.height);
            canvas.toBlob(
              async blob => {
                const urlRes = await getImageUploadUrl({
                  variables: {
                    file_type: blob?.type || '',
                  },
                });

                logProgress(0.08);

                const url = urlRes?.data?.create_image_upload_url?.url;
                if (!url) throw new Error();

                const uploadComplete = await new Promise<boolean>(res => {
                  try {
                    const oReq = new XMLHttpRequest();
                    oReq.open('PUT', url);

                    oReq.addEventListener('load', () => {
                      logProgress(1);
                      res(true);
                    });

                    oReq.upload.onprogress = e => logProgress(Math.max(0.1, e.loaded / e.total));

                    oReq.addEventListener('error', e => {
                      console.error('file upload error', e);
                      res(false);
                    });

                    oReq.setRequestHeader('content-type', blob?.type || '');

                    oReq.send(blob);
                  } catch (e) {
                    console.error('file upload error', e);
                    res(false);
                  }
                });

                if (uploadComplete) {
                  await doFinialiseUplaod({
                    variables: {
                      file_id: urlRes?.data?.create_image_upload_url?.id || '',
                      input: {
                        period,
                        type,
                        side,
                      },
                    },
                  });
                }

                resolve(true);
              },
              'image/webp',
              1,
            );

            // https://storage.googleapis.com/images-skinkitz-staging/4rJB0nQMsYg9UOx7ZUB20ezfO7VXgEDCAVto
          };
          imgEl.onerror = () => {
            setError(true);
            resolve(true);
          };
          imgEl.crossOrigin = 'anonymous';
          imgEl.src = typeof dataUrl === 'string' ? dataUrl : '';

          await promise;
        } catch (error) {
          console.error(error);
        } finally {
          logProgress(0);
          setError(false);
          handleClose();
        }
      };

      reader.onerror = () => {
        setError(true);
      };

      reader.readAsDataURL(file);
    } catch (e) {
      console.error('file upload error', e);
    }
  };

  return (
    <div className={classes.root}>
      {progress ? (
        <CircularProgress variant="determinate" value={progress} />
      ) : (
        <>
          <Button variant="contained" color="primary" component="label">
            Select an image
            <input
              type="file"
              accept="image/png, image/gif, image/jpeg, image/webp, image/heic"
              hidden
              onChange={_handleUpload}
            />
          </Button>
          {error && (
            <Typography variant="body1">
              This type of file isn&apos;t supported, please try again with JPG or PNG.
            </Typography>
          )}
        </>
      )}
    </div>
  );
}

export default Uploader;
