import { SubscriptionLevel, TaskTypes } from 'types';

export const isProduction = process.env.NEXT_PUBLIC_ENV === 'production';

export enum FormTitle {
  SIGN_UP = 'Getting To Know You',
  CHECK_IN = 'Check-In',
  ADVANCED = 'Advanced Questionnaire',
  ISOTRETINOIN = 'Isotretinoin Consent',
}

export enum FormType {
  SIGN_UP = 'getting-to-know-you',
  CHECK_IN = 'check-in',
  ADVANCED = 'advanced-questionnaire',
  ISOTRETINOIN = 'isotretinoin-consent',
}

type FormData = {
  slug: string;
  title: FormTitle;
  type: FormType;
  isCurrent: boolean;
  isProduction?: boolean;
};

type SignUpFormData = {
  slug: string;
  title: FormTitle.SIGN_UP;
  type: FormTitle.SIGN_UP;
  isCurrent: boolean;
  isProduction: boolean;
};

const allForms: readonly (FormData | SignUpFormData)[] = [
  {
    slug: '2o67qdqr',
    title: FormTitle.SIGN_UP,
    type: FormType.SIGN_UP,
    isCurrent: true,
    isProduction: true,
  },
  {
    slug: '4epcczvo',
    title: FormTitle.SIGN_UP,
    type: FormType.SIGN_UP,
    isCurrent: true,
    isProduction: false,
  },
  {
    slug: 'cdchrxzw',
    title: FormTitle.CHECK_IN,
    type: FormType.CHECK_IN,
    isCurrent: true,
  },
  {
    slug: 'bk2gtrgk',
    title: FormTitle.ADVANCED,
    type: FormType.ADVANCED,
    isCurrent: true,
  },
  {
    slug: 'o0lheq5y',
    title: FormTitle.ISOTRETINOIN,
    type: FormType.ISOTRETINOIN,
    isCurrent: true,
  },
];

function checkForms() {
  if (
    allForms.some(({ type }) => {
      return (
        allForms.filter(form => form.type === type && form.isCurrent && form.isProduction).length >
        1
      );
    }) ||
    allForms.some(({ type }) => {
      return (
        allForms.filter(form => form.type === type && form.isCurrent && !form.isProduction).length >
        1
      );
    })
  )
    throw new Error('more than one current form detected ');
}
checkForms();

export function findCurrentFormFromType(type: FormType): FormData | SignUpFormData | undefined {
  return allForms.find(
    form =>
      form.type === type &&
      form.isCurrent &&
      (form.type === FormType.SIGN_UP ? form.isProduction === isProduction : true),
  );
}

export function findAllFormsFromType(type: FormType): (FormData | SignUpFormData)[] {
  return allForms.filter(
    form =>
      form.type === type &&
      (form.type === FormType.SIGN_UP ? form.isProduction === isProduction : true),
  );
}

export function findFormFromSlug(slug: string): FormData | SignUpFormData | undefined {
  return allForms.find(
    form =>
      form.slug === slug &&
      form.isCurrent &&
      (form.type === FormType.SIGN_UP ? form.isProduction === isProduction : true),
  );
}

export function findAllCurrentForms(): (FormData | SignUpFormData)[] {
  return allForms.filter(
    form =>
      form.isCurrent &&
      (form.type === FormType.SIGN_UP ? form.isProduction === isProduction : true),
  );
}

export function getFormTaskType(type: FormType): TaskTypes | undefined {
  switch (type) {
    case FormType.CHECK_IN:
      return 'check-in';
    case FormType.ADVANCED:
      return 'advanced-questionnaire';
    case FormType.SIGN_UP:
      return 'initial-form';
    case FormType.ISOTRETINOIN:
      return 'isotretinoin-consent';
    default:
      throw new Error('FormType not matched to TaskType');
  }
}

export const typeMap = {
  'profile-upload': {
    action: '/?action=profile-upload',
    text: 'Please upload a profile photo for your account and dashboard',
  },
  'initial-photo-upload': {
    action: '/progress',
    text: 'Please upload your photos for assessment',
  },
  'progress-upload': {
    action: '/progress',
    text: 'Please upload your new progress photos for assessment',
  },
  'additional-photos-request': {
    action: '/progress',
    text: 'Our dermatologist has requested additional photos to help plan your treatment, please upload these now for assessment',
  },
  'advanced-questionnaire': {
    action: 'form',
    text: 'Based on your responses, we need some additional information to better support you. Please click here to complete the Advanced Questionnaire at your earliest convenience.',
  },
  'isotretinoin-consent': {
    action: 'form',
    text: 'Based on your responses, we need some additional information to better support you. Please click here to complete the Isotretinoin Consent at your earliest convenience.',
  },
  'initial-form': {
    action: 'form',
    text: 'Thank you for joining the SkinKitz Dashboard. Please click here to complete the Getting To Know You Fact Finder at your earliest convenience.',
  },
  'check-in': {
    action: 'form',
    text: "It's been a few weeks since we last checked-in, please click here to complete the Check In Fact Finder at your earliest convenience.",
  },
};

export const splashImages = [
  { url: '/assets/images/splash-1.jpg', alt: 'Woman applying cream to her face' },
  { url: '/assets/images/splash-2.png', alt: 'Woman applying cream to her face' },
  { url: '/assets/images/splash-3.png', alt: 'Man grinning at camera, eyes pointing to his right' },
  { url: '/assets/images/splash-4.png', alt: 'Man applying cream to his face' },
  { url: '/assets/images/splash-5.png', alt: 'Woman applying cream to her face' },
];

export const getSubscriptionLevelsData = (
  isProduction: boolean,
): {
  level: SubscriptionLevel;
  key: string;
  title: string;
  subTitle: string;
  prescriptionTopical: boolean;
  prescriptionTablets: boolean;
  consultIncluded: boolean;
  price_id: string;
  prior_price_ids: string[];
  price_cents: number;
}[] => [
  {
    level: 1,
    key: 'level-1',
    title: 'Level 1 Consult',
    subTitle: 'Suitable for very mild acne & congested skin',
    prescriptionTopical: false,
    prescriptionTablets: false,
    consultIncluded: false,
    price_id: isProduction ? 'price_1KqGd5G83OZuHPEAfhAS8zH0' : 'price_1KqH2hG83OZuHPEA2GWz7iC1',
    prior_price_ids: isProduction ? [] : ['price_1KqGWAG83OZuHPEAKFeRQQt5'],
    price_cents: 900,
  },
  {
    level: 2,
    key: 'level-2',
    title: 'Level 2 Consult',
    subTitle: 'Suitable for mild acne',
    prescriptionTopical: true,
    prescriptionTablets: false,
    consultIncluded: false,
    price_id: isProduction ? 'price_1KqGdqG83OZuHPEAK4XKhy73' : 'price_1KqGYGG83OZuHPEAEOxEQek9',
    prior_price_ids: isProduction ? [] : ['price_1JYjlrG83OZuHPEAPo3euYkM'],
    price_cents: 1900,
  },
  {
    level: 3,
    key: 'level-3',
    title: 'Level 3 Consult',
    subTitle: 'Suitable for moderate acne',
    prescriptionTopical: true,
    prescriptionTablets: true,
    consultIncluded: false,
    price_id: isProduction ? 'price_1KqGeSG83OZuHPEARgw08dvZ' : 'price_1KqH1zG83OZuHPEA4gGbH24f',
    prior_price_ids: isProduction ? [] : ['price_1KqGZQG83OZuHPEAtN5zM0OK'],
    price_cents: 4900,
  },
  {
    level: 4,
    key: 'level-4',
    title: 'Level 4 Consult',
    subTitle: 'Suitable for severe acne',
    prescriptionTopical: true,
    prescriptionTablets: true,
    consultIncluded: true,
    price_id: isProduction ? 'price_1KqGgMG83OZuHPEAj5ZEiy35' : 'price_1KqGaGG83OZuHPEAWmgtt2k5',
    prior_price_ids: isProduction ? [] : ['price_1JYjo5G83OZuHPEAHQ2ILidd'],
    price_cents: 11900,
  },
];

export const getTeleVideoConsultationPriceId = (isProduction: boolean): string =>
  isProduction ? 'price_1JXwLHG83OZuHPEAcWoCdacN' : 'price_1JY6hPG83OZuHPEAWset0sWq';

export const skinPlusProductTagPrescriptionRequired = 'skinkitz-dashboard-prescription-required';
export const skinPlusProductVendorRequire = 'SkinKitz';

export const prescriptionReferenceNumberStartingPoint = 75240;

export const userUploadedFilesBucketName = (isProduction: boolean): string =>
  isProduction ? 'uploaded-files-skinkitz-production' : 'uploaded-files-skinkitz-staging';
export const prescriptionsBucketName = (isProduction: boolean): string =>
  isProduction ? 'prescriptions-skinkitz-production' : 'prescriptions-skinkitz-staging';
export const imagesBucketName = (isProduction: boolean): string =>
  isProduction ? 'images-skinkitz-production' : 'images-skinkitz-staging';
