import React from 'react';
import Image from 'next/image';

import { makeStyles } from '@material-ui/core/styles';

import Logo from 'public/assets/logos/white.png';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100vw',
    height: ({ innerHeight }: { innerHeight: string }) => innerHeight,
    maxHeight: ({ innerHeight }: { innerHeight: string }) => innerHeight,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'linear-gradient(0deg, rgba(89,208,199,1) 0%, rgba(138,140,189,1) 100%)',
    zIndex: 10000,
  },
  logo: {
    animationName: '$pulse',
    animationDuration: '1.5s',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate',
    animationTimingFunction: 'cubic-bezier(0.76, 0, 0.24, 1)',
    opacity: 1,
  },
  '@keyframes pulse': {
    '0%': {
      opacity: 1,
      filter: 'blur(0px)',
    },
    '100%': {
      opacity: 0,
      filter: 'blur(4px)',
    },
  },
});

export default function Splash(): React.ReactElement {
  const classes = useStyles({
    innerHeight: typeof window !== 'undefined' ? `${window.innerHeight}px` : '100vh',
  });

  return (
    <div className={classes.root}>
      <Image
        className={classes.logo}
        width={160}
        height={160}
        layout="fixed"
        src={Logo}
        alt="logo"
        placeholder="blur"
      />
    </div>
  );
}
